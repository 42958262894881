<template>
  <section class="similar-advertisement">
    <!--  title pc -->
    <div class="block-title">
      <img src="../../assets/images/line-dot-left.svg" alt="icon line dot">
      <h2>Recommend</h2>
      <span>この広告を利用した方はこちらもチェックしています。</span>
      <img class="line-right" src="../../assets/images/line-dot-right.svg" alt="icon line dot">
    </div>

    <!-- slick list similar ads -->
    <VueSlickCarousel v-if="list.length"
                      v-bind="settings"
                      class="wrap-list">
      <div v-for="(ads, index) in list"
           class="wrap-advertising"
        :key="index">
        <div @click="changeAds(ads.id)"
                     class="advertising">
          <img :src="(ads.file ? ads.file.path : '') | imageEmpty"
               :alt="ads.title">

          <div class="wrap-content">
            <!-- content category -->
            <div class="content-advertising">
              <h4 v-text="ads.title" class="line-clamp-02"/>
              <div class="buy-label line-clamp-01"
                   v-html="ads.list_description"/>
            </div>

            <div class="point-rate">
              <p v-if="ads.is_campaign === 1
                 && checkDateBetween(ads.campaign_start_datetime, ads.campaign_end_datetime)">
                通常
                <!--fixed amount-->
                <span v-if="ads.point_type === 1">
                  {{ ads.normal_pointback_amount | numberWithCommas(',', 'P')}}
                </span>

                <!--fixed rate-->
                <span v-else>
                  {{ ads.normal_pointback_rate | numberWithCommas(',', '%P') }}
                </span>
              </p>
              <!--Campaign use-->
              <strong v-if="ads.is_campaign === 1
                  && checkDateBetween(ads.campaign_start_datetime, ads.campaign_end_datetime)">
                <!--fixed amount-->
                <template v-if="ads.point_type === 1">
                  {{ ads.campaign_pointback_amount | numberWithCommas(',', 'P') }}
                </template>
                <!--fixed rate-->
                <template v-else>
                  {{ ads.campaign_pointback_rate | numberWithCommas(',') }}<span>%</span>P
                </template>
              </strong>

              <!--Campaign not use-->
              <strong v-else>
                <!--fixed amount-->
                <template v-if="ads.point_type === 1">
                  {{ ads.normal_pointback_amount | numberWithCommas(',', 'P') }}
                </template>
                <!--fixed rate-->
                <template v-else>
                  {{ ads.normal_pointback_rate | numberWithCommas(',') }}<span>%</span>P
                </template>
              </strong>
            </div>
          </div>
        </div>
      </div>
    </VueSlickCarousel>
  </section>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import Common from '@/mixins/common.mixin'

export default {
  name: 'SimilarAdvertisement',

  components: {
    VueSlickCarousel
  },

  mixins: [Common],

  props: {
    list: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      settings: {
        arrows: true,
        dots: true,
        autoplay: false,
        speed: 500,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
          {
            breakpoint: 1160,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 910,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              rows: 4,
              slidesPerRow: 1,
              dots: true
            }
          }
        ]
      }
    }
  },

  methods: {
    changeAds (id) {
      this.$emit('change', id)
    }
  }
}
</script>

<style lang="scss" scoped>
section {
  width: 100%;
  margin: auto;
  z-index: 1;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/images/bg-grid.svg");
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
    @media #{$info-phone} {
      width: 100vw;
      left: -18px;
    }
  }
  .title {
    top: -23px;
    display: block;
    margin: auto;
    position: relative;
    @media #{$info-phone} {
      top: 0;
      padding-top: 19px;
      margin-bottom: 43px;
    }
  }
  .block-title {
    position: relative;
    padding: 50px 0 22px;
    margin-bottom: 23px;
    @media #{$info-phone} {
      padding: 28px 0 23px;
    }
    h2 {
      letter-spacing: 2px;
      -webkit-text-stroke: 1px #C66C44;
      @include font-size(36px);
      font-weight: normal;
      @media #{$info-tablet-horizontal} {
        @include font-size(30px);
      }
      @media #{$info-phone} {
        font-size: 28px;
        margin-bottom: 8px;
      }
    }
    span {
      margin-bottom: 0;
      color: #D98F6D;
      font-weight: normal;
      @include font-size(20px);
      @media #{$info-tablet-horizontal} {
        @include font-size(15px);
      }
      @media #{$info-phone} {
        font-size: 20px;
      }
    }
    img {
      position: absolute;
      left: 48px;
      bottom: 0;
      @media #{$info-tablet-horizontal} {
        width: 85px;
        bottom: 2px;
      }
      @media #{$info-tablet-vertical} {
        width: 65px;
        bottom: 17px;
        left: 15px;
      }
      @media #{$info-phone} {
        display: none;
      }
      &.line-right {
        left: unset;
        right: 52px;
        bottom: unset;
        top: -23px;
        @media #{$info-tablet-horizontal} {
          top: -10px;
        }
        @media #{$info-tablet-vertical} {
          top: -5px;
          right: 15px;
        }
      }
    }
  }
  .wrap-list {
    padding-bottom: 100px;
    @media #{$info-phone} {
      padding-bottom: 92px;
    }
    .wrap-advertising {
      padding: 0 4px;
      @media #{$info-phone} {
        margin-bottom: 7px;
      }
      .advertising {
        width: 100%;
        position: relative;
        z-index: 1;
        padding: 16px;
        background: #F5F0ED;
        border-radius: 6px;
        min-height: 364px;
        color: #282828;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media #{$info-phone} {
          flex-direction: row;
          justify-content: unset;
          align-items: flex-start;
          min-height: 131px;
          padding: 9px 18px 9px 9px;
        }
        img {
          display: block;
          width: 171px;
          height: 171px;
          margin: 0 auto 16px;
          object-fit: fill;
          @media #{$info-phone} {
            width: 100px;
            height: 100px;
            margin: auto 20px auto 0;
          }
        }
        .wrap-content {
          min-height: 170px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          @media #{$info-phone} {
            min-height: unset;
          }
          .point-rate {
            @media #{$info-phone} {
              position: absolute;
              bottom: 9px;
              right: 18px;
            }
          }
        }
        .content-advertising {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          @media #{$info-phone} {
            width: 100%;
          }
          h4 {
            font-family: $font-family-title-B;
            @include font-size(16px);
            margin-bottom: 16px;
            color: #C66C44;
            @media #{$info-phone} {
              margin-bottom: 7px;
              font-size: 16px;
              line-height: 1.3;
            }
          }
        }
        .buy-label {
          word-break: break-all;
          @include font-size(12px);
          margin-bottom: 36px;
          @media #{$info-phone} {
            font-size: 13px;
            margin-bottom: 40px
          }
        }
        p {
          @include font-size(12px);
          text-align: right;
          @media #{$info-phone} {
            font-size: 10px;
          }
        }
        strong {
          font-family: $font-label;
          color: #C66C44;
          @include font-size(28px);
          font-style: italic;
          font-weight: normal;
          line-height: 1;
          display: block;
          text-align: right;
          @media #{$info-phone} {
            font-size: 24px;
          }
          span {
            @include font-size(21px);
            @media #{$info-phone} {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

</style>
