<template>
  <ValidationProvider
    v-slot="{ errors }"
    tag="div"
    :name="field"
    :vid="vid"
    :rules="rules">
    <!--Field-->
    <div class="date__field position-relative"
         :class="{'errors__field': errors[0]}">
      <!--Date-->
      <date-picker :value="value"
                   :type="date"
                   :value-type="valueType"
                   :format="format"
                   :placeholder="placeholder"
                   :disabled-date="disableDate"
                   :disabled="disabled"
                   :class="[{ 'hidden-input' : value }, { 'bg-disable' : disabled }]"
                   @change="onChange"/>
      <span class="custom-format">{{ value | formatDate('YYYY年MM月DD日') }}</span>
    </div>

    <!--Message Error-->
    <span
      v-if="errors[0]"
      class="errors">
      {{ errors[0] }}
    </span>
  </ValidationProvider>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ja'

export default {
  name: 'InputDatepicker',

  model: {
    prop: 'value',
    event: 'change'
  },

  components: {
    DatePicker
  },

  props: {
    field: {
      type: String,
      default: ''
    },

    rules: {
      type: String,
      default: ''
    },

    vid: {
      type: String,
      default: ''
    },

    value: {
      type: String,
      default: ''
    },

    date: {
      type: String,
      default: 'date'
    },

    valueType: {
      type: String,
      default: 'format'
    },

    format: {
      type: String,
      default: 'YYYY-MM-DD'
    },

    placeholder: {
      type: String,
      default: '年 / 月 / 日'
    },

    startDateDisable: {
      type: [String, Date],
      default: ''
    },

    endDateDisable: {
      type: [String, Date],
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onChange (val) {
      this.$emit('change', val)
    },

    disableDate (date) {
      const startDate = this.startDateDisable || new Date('1950-01-01')
      return date < startDate || date > new Date(this.endDateDisable)
    }
  }
}
</script>

<style lang="scss" scoped>
  .date__field {
    input {
      width: 100%;
      padding: 7px;
      border-radius: 6px;
      @include font-size(20px);
      @media #{$info-phone} {
        font-size: 16px;
      }
    }
  }
</style>
